import React from 'react';
import { BarChart, Bar, Label, XAxis, YAxis, Tooltip, Legend, CartesianGrid, ResponsiveContainer } from 'recharts';



export default function FeedCompChart({ FeedCompInfo }) {
return <ResponsiveContainer width="90%" height={400}>
        <BarChart data={FeedCompInfo}
                    margin={{ top: 20, right: 30, left: 50, bottom: 20 }} // Adjusted margin

        >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="range">
            <Label
    value="CPC $ (Original)"
    position="center"
    offset={0}
    content={props => {
        return (
        <text
            style={{ fontSize: "15px" }}
            x={props.viewBox.x + props.viewBox.width}
            y={props.viewBox.y + props.viewBox.height + 3}
            text-anchor="end"
            fill="#000000"
        >
            {props.value}
        </text>
        );
    }}
    />
            </XAxis> 
            <YAxis>
                <Label angle={270} position='left' style={{ textAnchor: 'middle' }}>
                    Job Count #
                </Label>
            </YAxis>
            <Tooltip />
            <Legend />
                <Bar dataKey="jobg8" fill="#DC143C" />
                <Bar dataKey="jobrapido" fill="#8884d8" />
                <Bar dataKey="jooble" fill="#ff8f00" />
                <Bar dataKey="jobget" fill="#82ca9d" />
                <Bar dataKey="jobvertise" fill="#0047AB" />
        </BarChart>
    </ResponsiveContainer>


}
