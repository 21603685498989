import React from 'react';
import Table from '@mui/joy/Table';
// import TableBody from '@mui/joy/TableBody';
// import TableRow from '@mui/joy/TableRow';
// import TableCell from '@mui/joy/TableCell';
import Sheet from "@mui/joy/Sheet";


const DataTable = ({ data, valueName }) => {
  // Convert the object to an array of objects for easy table rendering
  const rows = Object.entries(data).map(([date, value]) => ({
    date,
    value
  }));

  // Render the table UI
  return (
    <Sheet
    className="OrderTableContainer"
    variant="outlined"
    sx={{
      display: { xs: "none", sm: "initial" },
      width: "100%",
      borderRadius: "sm",
      flexShrink: 1,
      overflow: "auto",
      minHeight: 0,
    }}
  >
    <Table>
      <thead>
        <tr>
          <td>Date</td>
          <td>{valueName}</td>
        </tr>
      </thead>
      <tbody>
        {rows.map((row, index) => (
          <tr key={index}>
            <td>{row.date}</td>
            <td>{row.value}</td>
          </tr>
        ))}
      </tbody>
    </Table>
    </Sheet>
  );
};

export default DataTable;
