import * as React from "react";
import { useState, useMemo } from "react";
import Table from "@mui/joy/Table";
import Sheet from "@mui/joy/Sheet";
import Button from "@mui/joy/Button";

export default function IndividualClicksTable({ clickEntries: initialEntries }) {
  const [clickEntries, setClickEntries] = useState(initialEntries);
  const [page, setPage] = useState(1);
  const rowsPerPage = 10; // Set the number of rows per page

  // Sort the data by date (assuming "Timestamp" is a valid date string)
  const sortedReviewStats = useMemo(() => {
    return [...clickEntries].sort((a, b) => new Date(b.Timestamp) - new Date(a.Timestamp));
  }, [clickEntries]);

  // Calculate the displayed rows based on the current page
  const displayedRows = sortedReviewStats?.slice(
    (page - 1) * rowsPerPage,
    page * rowsPerPage
  );

  const handleRowDeletion = async (row) => {
    try {
      const response = await fetch('/api/delete_click', { // Adjust API route as needed
        method: 'POST',
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({  
          AuthoringPartner: row["AuthoringJobPartner"],
          TrafficPartner: row["TrafficSendingPartner"],
          Timestamp: row["Timestamp"]
        })
      });

      if (!response.ok) {
        alert("An error occurred while deleting the row...");
        throw new Error('Network response was not ok');
      }

      const result = await response.json();
      console.log('Success:', result);

      // Remove the deleted row from the current clickEntries
      setClickEntries((prevEntries) =>
        prevEntries.filter(
          (entry) => 
            entry.AuthoringJobPartner !== row["AuthoringJobPartner"] ||
            entry.TrafficSendingPartner !== row["TrafficSendingPartner"] ||
            entry.Timestamp !== row["Timestamp"]
        )
      );

      alert("Row successfully deleted");
    } catch (error) {
      console.error('Error:', error);
    }
  };

  // Handle pagination
  const totalPages = Math.ceil(sortedReviewStats?.length / rowsPerPage);

  const handlePrevPage = () => {
    setPage((prev) => Math.max(prev - 1, 1));
  };

  const handleNextPage = () => {
    setPage((prev) => Math.min(prev + 1, totalPages));
  };

  // Adjust the current page if the deletion leaves the current page empty
  React.useEffect(() => {
    if (page > totalPages) {
      setPage(totalPages);
    }
  }, [page, totalPages]);

  return (
    <React.Fragment>
      <Sheet
        className="OrderTableContainer"
        variant="outlined"
        sx={{
          display: { xs: "none", sm: "initial" },
          width: "100%",
          borderRadius: "sm",
          flexShrink: 1,
          overflow: "auto",
          minHeight: 0,
        }}
      >
        <Table
          aria-labelledby="tableTitle"
          stickyHeader
          hoverRow
          sx={{
            "--TableCell-headBackground":
              "var(--joy-palette-background-level1)",
            "--Table-headerUnderlineThickness": "1px",
            "--TableRow-hoverBackground":
              "var(--joy-palette-background-level1)",
            "--TableCell-paddingY": "4px",
            "--TableCell-paddingX": "8px",
          }}
        >
          <thead>
            <tr>
              <th style={{ padding: "12px 6px", textAlign: "center" }}>
                Time Clicked
              </th>
              <th style={{ padding: "12px 6px", textAlign: "center" }}>
                Listing Origin
              </th>
              <th style={{ padding: "12px 6px", textAlign: "center" }}>
                Original CPC
              </th>              
              <th style={{ padding: "12px 6px", textAlign: "center" }}>
                Traffic From
              </th>
              <th style={{ padding: "12px 6px", textAlign: "center" }}>
                Traffic CPC
              </th>
              <th style={{ padding: "12px 6px", textAlign: "center" }}>
                Margin %
              </th>
              <th style={{ padding: "12px 6px", textAlign: "center" }}>
                Location
              </th>
              <th style={{ padding: "12px 6px", textAlign: "center" }}>
                Device
              </th>
              <th style={{ padding: "12px 6px", textAlign: "center" }}>
                Options
              </th>      
            </tr>
          </thead>
          <tbody>
            {displayedRows?.map((row, index) => (
              <tr key={`${row.feedId}-${index}`}>
                <td style={{ textAlign: "center" }}>{row["Timestamp"]}</td>
                <td style={{ textAlign: "center" }}>{row["AuthoringJobPartner"]}</td>
                <td style={{ textAlign: "center" }}>{row["AuthorCPC"]}</td>
                <td style={{ textAlign: "center" }}>{row["TrafficSendingPartner"]}</td>
                <td style={{ textAlign: "center" }}>{row["TrafficCPC"]}</td>
                <td style={{ textAlign: "center" }}>{row["Margin"] * 100} %</td>
                <td style={{ textAlign: "center", fontSize: "0.80rem" }}>{row["city"]}, {row["state"]}, {row["country"]}</td>
                <td style={{ textAlign: "center", fontSize: "0.60rem" }}>{row["UserAgent"]}</td>
                <td style={{ textAlign: "center" }}>
                  <Button color="danger" onClick={() => handleRowDeletion(row)}>Delete</Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>

        {/* Custom Pagination Controls */}
        <div style={{ display: "flex", justifyContent: "center", margin: "16px 0" }}>
          <button onClick={handlePrevPage} disabled={page === 1}>
            Previous
          </button>
          <span style={{ margin: "0 10px" }}>
            Page {page} of {totalPages}
          </span>
          <button onClick={handleNextPage} disabled={page === totalPages}>
            Next
          </button>
        </div>
      </Sheet>
    </React.Fragment>
  );
}
