import * as React from "react";
import { useNavigate } from "react-router-dom";
import Box from "@mui/joy/Box";
import Sheet from "@mui/joy/Sheet";
import List from "@mui/joy/List";
import ListSubheader from "@mui/joy/ListSubheader";
import ListItem from "@mui/joy/ListItem";
import ListItemContent from "@mui/joy/ListItemContent";
import ListItemDecorator from "@mui/joy/ListItemDecorator";
import ListItemButton from "@mui/joy/ListItemButton";
import TroubleshootIcon from "@mui/icons-material/Troubleshoot";
// icons
import {
  TrendingUp,
  CurrencyExchangeOutlined,
  ShoppingBagOutlined
} from "@mui/icons-material";
import {
  VpnKey,
  InventoryOutlined,
  SmartToyOutlined,
  BadgeOutlined,
  FeedOutlined
} from "@mui/icons-material";
import { HomeOutlined, ShoppingCartOutlined } from "@mui/icons-material";
import FeedIcon from "@mui/icons-material/Feed";
import StarsIcon from "@mui/icons-material/Stars";
import CreditCardRoundedIcon from "@mui/icons-material/CreditCardRounded";
import LogoutRoundedIcon from "@mui/icons-material/LogoutRounded";
import { RateReviewOutlined } from "@mui/icons-material";
import { closeSidebar } from "../utils";
import { InterestsOutlined } from "@mui/icons-material";
// import { VolunteerActivism } from "@mui/icons-material";
import { Loyalty } from "@mui/icons-material";
import { Button, Card, Stack, LinearProgress, Typography } from "@mui/joy";
import IconButton from "@mui/joy/IconButton";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { useState, useEffect } from "react";
import WorkIcon from '@mui/icons-material/Work';

export default function Sidebar() {
  const navigate = useNavigate();
  const [showPlanReminder, setShowPlanReminder] = useState(true);

  function upgradePlan() {
    navigate("/reviews");
  }

  function logout() {
    // Remove token from storage (example using localStorage)
    localStorage.removeItem("token");
    // Redirect user to login page or show a logged-out state
    navigate("/partners/login");
  }

  return (
    <React.Fragment>
      <Box
        className="Sidebar-overlay"
        sx={{
          position: "fixed",
          zIndex: 9998,
          top: 0,
          left: 0,
          width: "100vw",
          height: "100vh",
          opacity: "var(--SideNavigation-slideIn)",
          backgroundColor: "var(--joy-palette-background-backdrop)",
          transition: "opacity 0.4s",
          transform: {
            xs:
              "translateX(calc(100% * (var(--SideNavigation-slideIn, 0) - 1) + var(--SideNavigation-slideIn, 0) * var(--FirstSidebar-width, 0px)))",
            lg: "translateX(-100%)"
          }
        }}
        onClick={() => closeSidebar()}
      />
      <Sheet
        className="Sidebar"
        color="neutral"
        sx={{
          position: {
            xs: "fixed",
            lg: "sticky"
          },
          transform: {
            xs:
              "translateX(calc(100% * (var(--SideNavigation-slideIn, 0) - 1) + var(--SideNavigation-slideIn, 0) * var(--FirstSidebar-width, 0px)))",
            lg: "none"
          },
          transition: "transform 0.4s",
          zIndex: 9999,
          height: "100dvh",
          top: 0,
          p: 2,
          flexShrink: 0,
          display: "flex",
          flexDirection: "column",
          gap: 2,
          borderRight: "1px solid",
          borderColor: "divider"
        }}
      >
        <List
          size="sm"
          sx={{
            "--ListItem-radius": "6px",
            "--List-gap": "6px"
          }}
        >
          <ListSubheader
            role="presentation"
            sx={{ fontSize: "l", fontWeight: "lg" }}
          >
            CareerSphere
          </ListSubheader>
          {/* TODO undo when home page is done. */}
          {/* <ListItem>
            <ListItemButton onClick={() => navigate("/partners/home")}>
              <ListItemDecorator>
                <HomeOutlined />
              </ListItemDecorator>
              <ListItemContent>Home</ListItemContent>
            </ListItemButton>
          </ListItem> */}
          {/* <ListSubheader role="presentation" sx={{ fontWeight: "lg" }}>
            Dashboard
          </ListSubheader> */}
{/* 
          <ListItem>
            <ListItemButton onClick={() => navigate("/templates")}>
              <ListItemDecorator>
                <StarsIcon />
              </ListItemDecorator>
              <ListItemContent>Templates</ListItemContent>
            </ListItemButton>
          </ListItem> */}

          {/* <ListItem>
            <ListItemButton onClick={() => navigate("/automation")}>
              <ListItemDecorator>
                <SmartToyOutlined />
              </ListItemDecorator>
              <ListItemContent>Automation</ListItemContent>
            </ListItemButton>
          </ListItem> */}
          <ListItem>
            <ListItemButton onClick={() => navigate("/partners/buying_traffic")}>
              <ListItemDecorator>
                <StarsIcon />
              </ListItemDecorator>
              <ListItemContent>Buying Traffic</ListItemContent>
            </ListItemButton>
          </ListItem>
          <ListItem>
            <ListItemButton onClick={() => navigate("/partners/receiving_jobs")}>
              <ListItemDecorator>
                <WorkIcon />
              </ListItemDecorator>
              <ListItemContent>Receiving Jobs</ListItemContent>
            </ListItemButton>
          </ListItem>





          {/* TODO state handling on selected over ListItemButton*/}

          {/* <ListSubheader role="presentation" sx={{ fontWeight: 700, mt: 2 }}>
            SETTINGS
          </ListSubheader> */}

          {/* <ListItem>
            <ListItemButton onClick={() => navigate("/feeds")}>
              <ListItemDecorator>
                <FeedOutlined />
              </ListItemDecorator>
              <ListItemContent>Feeds</ListItemContent>
            </ListItemButton>
          </ListItem> */}

          {/* <ListItem>
            <ListItemButton onClick={() => navigate("/security")}>
              <ListItemDecorator>
                <BadgeOutlined />
              </ListItemDecorator>
              <ListItemContent>Security</ListItemContent>
            </ListItemButton>
          </ListItem> */}

          {/* <ListItem>
            <ListItemButton onClick={() => navigate("/billing")}>
              <ListItemDecorator>
                <CreditCardRoundedIcon />
              </ListItemDecorator>
              <ListItemContent>Billing</ListItemContent>
            </ListItemButton>
          </ListItem> */}
          {/* <ListItem>
            <ListItemButton onClick={() => navigate("/api-keys")}>
              <ListItemDecorator>
                <VpnKey />
              </ListItemDecorator>
              <ListItemContent>API Keys</ListItemContent>
            </ListItemButton>
          </ListItem> */}

          <ListItem>
            <ListItemButton onClick={() => logout()}>
              <ListItemDecorator>
                <LogoutRoundedIcon />
              </ListItemDecorator>
              <ListItemContent>Logout</ListItemContent>
            </ListItemButton>
          </ListItem>
          {/* <br />
          {showPlanReminder &&
            <Card
              invertedColors
              variant="soft"
              color="warning"
              size="sm"
              sx={{ boxShadow: "none", pt: 2 }}
            >
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography level="title-sm">Complete Setup</Typography>
                <IconButton
                  size="sm"
                  onClick={() => setShowPlanReminder(false)}
                >
                  <CloseRoundedIcon />
                </IconButton>
              </Stack>
              <Typography level="body-xs">
                Your account hasn't signed up for
              </Typography>
              <Typography level="body-xs">
                review collection. Start now!
              </Typography>
              <LinearProgress
                variant="outlined"
                value={80}
                determinate
                sx={{ my: 1 }}
              />
              <Button size="sm" variant="solid" onClick={upgradePlan}>
                Upgrade plan
              </Button>
            </Card>} */}
        </List>
      </Sheet>
    </React.Fragment>
  );
}
