// import * as React from "react";
// import AspectRatio from "@mui/joy/AspectRatio";
// import Card from "@mui/joy/Card";
// import Skeleton from "@mui/joy/Skeleton";
// import Typography from "@mui/joy/Typography";

// export default function BasicSkeleton() {
//   return (
//     <Card
//       variant="outlined"
//       sx={{ minWidth: 600, minHeight: 400, display: "flex", gap: 2 }}
//     >
//       <Skeleton variant="overlay" />
//     </Card>
//   );
// }

import * as React from 'react';
import Card from '@mui/joy/Card';
import CardContent from '@mui/joy/CardContent';
import Skeleton from '@mui/joy/Skeleton';

export default function BasicSkeleton() {
  return (
    <Card
      variant="outlined"
      sx={{ width: 'max(900px, 100%)', height: 'max(220px, 80%)', borderRadius: 0, '--Card-radius': 0 }}
    >
      <CardContent>
        <Skeleton level="body-xs" variant="text" width="92%" />
        <Skeleton level="body-xs" variant="text" width="99%" />
        <Skeleton level="body-xs" variant="text" width="90%" />
        <Skeleton level="body-xs" variant="text" width="92%" />
      </CardContent>
      <CardContent>
        <Skeleton level="body-xs" variant="text" width="91%" />
        <Skeleton level="body-xs" variant="text" width="99%" />
        <Skeleton level="body-xs" variant="text" width="88%" />
        <Skeleton level="body-xs" variant="text" width="92%" />
      </CardContent>
      <CardContent>
        <Skeleton level="body-xs" variant="text" width="92%" />
        <Skeleton level="body-xs" variant="text" width="99%" />
        <Skeleton level="body-xs" variant="text" width="90%" />
        <Skeleton level="body-xs" variant="text" width="92%" />
      </CardContent>
      <CardContent>
        <Skeleton level="body-xs" variant="text" width="91%" />
        <Skeleton level="body-xs" variant="text" width="99%" />
        <Skeleton level="body-xs" variant="text" width="88%" />
        <Skeleton level="body-xs" variant="text" width="92%" />
      </CardContent>
      <CardContent>
        <Skeleton level="body-xs" variant="text" width="92%" />
        <Skeleton level="body-xs" variant="text" width="99%" />
        <Skeleton level="body-xs" variant="text" width="90%" />
        <Skeleton level="body-xs" variant="text" width="92%" />
      </CardContent>
      <CardContent>
        <Skeleton level="body-xs" variant="text" width="91%" />
        <Skeleton level="body-xs" variant="text" width="99%" />
        <Skeleton level="body-xs" variant="text" width="88%" />
        <Skeleton level="body-xs" variant="text" width="92%" />
      </CardContent>
    </Card>
  );
}