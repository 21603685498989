import * as React from "react";
import { useState, useMemo } from "react";
import Table from "@mui/joy/Table";
import Sheet from "@mui/joy/Sheet";


export default function JobPartnerUpdateTable({ JobUpdateLog }) {
  const [page, setPage] = useState(1);
  const rowsPerPage = 15; // Set the number of rows per page

  // Sort the data by date (assuming "name" is a valid date string)
  const sortedReviewStats = useMemo(() => {
    return [...JobUpdateLog].sort((a, b) => new Date(b.name) - new Date(a.name));
  }, [JobUpdateLog]);

  // Calculate the displayed rows based on the current page
  const displayedRows = sortedReviewStats?.slice(
    (page - 1) * rowsPerPage,
    page * rowsPerPage
  );

  // Handle pagination
  const totalPages = Math.ceil(sortedReviewStats?.length / rowsPerPage);

  const handlePrevPage = () => {
    setPage((prev) => Math.max(prev - 1, 1));
  };

  const handleNextPage = () => {
    setPage((prev) => Math.min(prev + 1, totalPages));
  };

  return (
    <React.Fragment>
      <Sheet
        className="OrderTableContainer"
        variant="outlined"
        sx={{
          display: { xs: "none", sm: "initial" },
          width: "45%",
          borderRadius: "sm",
          flexShrink: 1,
          overflow: "auto",
          minHeight: 0,
        }}
      >
        <Table
          aria-labelledby="tableTitle"
          stickyHeader
          hoverRow
          sx={{
            "--TableCell-headBackground":
              "var(--joy-palette-background-level1)",
            "--Table-headerUnderlineThickness": "1px",
            "--TableRow-hoverBackground":
              "var(--joy-palette-background-level1)",
            "--TableCell-paddingY": "4px",
            "--TableCell-paddingX": "8px",
          }}
        >
          <thead>
            <tr>
              <th style={{ padding: "12px 6px", textAlign: "center" }}>
              Job Partner
              </th>
              <th style={{ padding: "12px 6px", textAlign: "center" }}>
              Removed Jobs
              </th>
              <th style={{ padding: "12px 6px", textAlign: "center" }}>
              Added Jobs
              </th>
              <th style={{ padding: "12px 6px", textAlign: "center" }}>
              Total Jobs
              </th>   
              <th style={{ padding: "12px 6px", textAlign: "center" }}>
              Timestamp
              </th>
            </tr>
          </thead>
          <tbody>
            {displayedRows?.map((row, index) => (
              <tr key={`${row.feedId}-${index}`}>
                <td style={{ textAlign: "center" }}>{row["AuthoringPartner"]}</td>
                <td style={{ textAlign: "center" }}>{row["RemovedJobs"]}</td>
                <td style={{ textAlign: "center" }}>{row["AddedJobs"]}</td>
                <td style={{ textAlign: "center" }}>{row["TotalSnapshotFeedSize"]}</td>
                <td style={{ textAlign: "center" }}>{row["Timestamp"]}</td>
              </tr>
            ))}
          </tbody>
        </Table>

        {/* Custom Pagination Controls */}
        <div style={{ display: "flex", justifyContent: "center", margin: "16px 0" }}>
          <button onClick={handlePrevPage} disabled={page === 1}>
            Previous
          </button>
          <span style={{ margin: "0 10px" }}>
            Page {page} of {totalPages}
          </span>
          <button onClick={handleNextPage} disabled={page === totalPages}>
            Next
          </button>
        </div>
      </Sheet>
    </React.Fragment>
  );
}
