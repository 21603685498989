import * as React from "react";
import { CssVarsProvider, useColorScheme } from "@mui/joy/styles";

import Box from "@mui/joy/Box";
import CssBaseline from "@mui/joy/CssBaseline";
import IconButton from "@mui/joy/IconButton";

// Icons import
import DarkModeRoundedIcon from "@mui/icons-material/DarkModeRounded";
import LightModeRoundedIcon from "@mui/icons-material/LightModeRounded";
import AspectRatio from "@mui/joy/AspectRatio";

import Sidebar from "../../components/Sidebar";
import Header from "../../components/Header";

import ClicksChart from "../../components/ClicksChart";

import { Typography } from "@mui/joy";
import framesxTheme from "../theme";
// import SampleSkeletonTable from "../components/SampleSkeletonTable";

// import AspectRatio from '@mui/joy/AspectRatio';
import Button from "@mui/joy/Button";
import Card from "@mui/joy/Card";
import CardContent from "@mui/joy/CardContent";
import CardActions from "@mui/joy/CardActions";
import FormControl from "@mui/joy/FormControl";
import FormLabel from "@mui/joy/FormLabel";
import Input from '@mui/joy/Input';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { NavLink } from 'react-router-dom';
import 'react-day-picker/dist/style.css';
import Table from "@mui/joy/Table";
import Sheet from "@mui/joy/Sheet";
// import Typography from '@mui/joy/Typography';
import Stack from "@mui/joy/Stack";
import { useState, useEffect } from "react";
// import DatePicker from "../components/DatePicker";
import SampleSkeletonTable from "../../components/SampleSkeletonTable";
import DataTable from "../../components/DataTable";

// Page shows how much the user spent.



function ColorSchemeToggle() {
  const { mode, setMode } = useColorScheme();
  const [mounted, setMounted] = React.useState(false);
  React.useEffect(() => {
    setMounted(true);
  }, []);
  if (!mounted) {
    return null;
  }
  return (
    <IconButton
      id="toggle-mode"
      size="lg"
      variant="solid"
      color="neutral"
      onClick={() => {
        if (mode === "light") {
          setMode("dark");
        } else {
          setMode("light");
        }
      }}
      sx={{
        position: "fixed",
        zIndex: 999,
        top: "1rem",
        right: "1rem",
        borderRadius: "50%",
        boxShadow: "sm"
      }}
    >
      {mode === "light" ? <DarkModeRoundedIcon /> : <LightModeRoundedIcon />}
    </IconButton>
  );
}




export default function TeamExample() {
  const [byDayRevAuthored, setByDayRevAuthored] = useState([]);
  const [byDayClickAuthored, setByDayClickAuthored] = useState([]);
  // const []
  // TODO get today's delivered
  const get_click_data = "/api/get_click_data";
  
  const [loadedTable, setLoadedTable] = useState(false);
  // const [data, setData] = useState([]);


  useEffect(() => {
    const fetchData = async () => {      
      try {
        const response = await fetch(get_click_data, {
          method: "POST",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Content-Type": "application/json",
          },
          // body: JSON.stringify(dataToSend),
        });
        
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();


        console.log("data!: ", data)

      // Reverse the order of the day data
      const reversedByDayRevAuthored = Object.fromEntries(Object.entries(data.by_day_rev_sent).reverse());
      const reversedByDayClickAuthored = Object.fromEntries(Object.entries(data.by_day_click_sent).reverse());

      setByDayRevAuthored(reversedByDayRevAuthored);
      setByDayClickAuthored(reversedByDayClickAuthored);
      setLoadedTable(true)
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []); // Added startDate and endDate as dependencies for useEffect
  

  return (
    <CssVarsProvider disableTransitionOnChange theme={framesxTheme}>
      <CssBaseline />
      <Box sx={{ display: "flex", minHeight: "50dvh" }}> {/* Main flex container */}
        <Sidebar /> {/* Sidebar stays directly within the main flex container */}
        <Box sx={{ flex: 1, display: "flex", flexDirection: "column" }}> {/* New Box for main content */}
          <Header />
          <ColorSchemeToggle />
          <Box sx={{ width: '100%', my: 2 }}>
            <Typography component="h1" variant="h1" gutterBottom>
              Receiving Jobs
            </Typography>
          </Box>
          {loadedTable ? (
            <Box sx={{ display: 'flex', justifyContent: 'space-evenly', width: '100%' }}>
              <DataTable data={byDayRevAuthored} valueName={"Daily Earnings ($)"} />
              <DataTable data={byDayClickAuthored} valueName={"Daily Clicks"} />
            </Box>
          ) : (
            <SampleSkeletonTable />
          )}
        </Box>
      </Box>
    </CssVarsProvider>
  );
}
