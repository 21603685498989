        import * as React from "react";
        import { useState, useMemo } from "react";
        import Table from "@mui/joy/Table";
        import Sheet from "@mui/joy/Sheet";
        import Button from "@mui/joy/Button";
        import Modal from "@mui/joy/Modal";
        import ModalClose from "@mui/joy/ModalClose";
        import Typography from "@mui/joy/Typography";
        import Box from '@mui/joy/Box';
        import Input from '@mui/joy/Input';

        export default function OverridesTable({ overrides, override_ranges, all_partners }) {
            const [open, setOpen] = React.useState(false);
            const [inputValue, setInputValue] = useState('');
            const [error, setError] = useState('');
            const [margin, setMargin] = useState('');
            const [authoringPartner, setAuthoringPartner] = useState('');
            const [trafficPartner, setTrafficPartner] = useState('');
            const [minCPC, setMinCPC] = useState(-1.0);
            const [maxCPC, setMaxCPC] = useState(-1.0);

        // Validation function for margin
        const validateMargin = (value) => {
            const intValue = parseInt(value, 10);
            return !isNaN(intValue) && Number.isInteger(intValue);
        };

        const handleAuthoringPartnerChange = (event) => {
            setAuthoringPartner(event.target.value);
        };
        

        const handleTrafficPartnerChange = (event) => {
            setTrafficPartner(event.target.value);
        };

        const handleMarginChange = (event) => {
            if (event && event.target) {
            setMargin(event.target.value);
            }
        };

        const handleCancel = () => {
            // Your cancel logic here
            setAuthoringPartner('');
            setTrafficPartner('');
            setMargin('');
            setInputValue('');
            setError('');
        };

        const validateInput = (value) => {
            // Split the input by comma and trim any whitespace
            const rangeParts = value.split(',').map(part => part.trim());
        
            // Ensure there are exactly two parts (start and end of range)
            if (rangeParts.length !== 2) {
                setError('Please enter exactly two comma-separated decimal numbers.');
                return false;
            }
        
            // Parse the parts into floats
            const start = parseFloat(rangeParts[0]);
            setMinCPC(start);
            const end = parseFloat(rangeParts[1]);
            setMaxCPC(end);
        
            // Regular expression to validate positive decimal numbers with up to two decimal places (but no negatives)
            const isValidDecimal = (num) => /^[0-9]+(\.\d{1,2})?$/.test(num) && num > 0;
        
            // Validate that both values are valid positive decimal numbers (no negatives or zero), and start < end
            if (isNaN(start) || isNaN(end) || start >= end || !isValidDecimal(start) || !isValidDecimal(end)) {
                setError('Please enter two valid positive decimal numbers with up to two decimal places, where the first is less than the second.');
                return false;
            }
        
            // Function to check if the input range intersects with override_ranges considering authoring and traffic partners
            const rangesIntersect = (range, listOfRanges) => {
                const [rangeStart, rangeEnd] = range;
                const epsilon = 0.000001; // Small tolerance for floating-point comparisons
        
                // Check each range in the listOfRanges, which now includes authoringPartner and trafficPartner
                for (const [listStart, listEnd, listAuthoringPartner, listTrafficPartner] of listOfRanges) {
                    // Check if this entry matches the selected authoring and traffic partners
                    if (listAuthoringPartner === authoringPartner && listTrafficPartner === trafficPartner) {
                        // Check if ranges overlap, allowing for floating-point precision errors
                        if (Math.max(rangeStart, listStart) - Math.min(rangeEnd, listEnd) <= epsilon) {
                            return true; // Intersection found
                        }
                        // Additional check for exact overlap or complete equality
                        if (
                            Math.abs(rangeStart - listStart) < epsilon &&
                            Math.abs(rangeEnd - listEnd) < epsilon
                        ) {
                            return true; // Exact overlap (e.g., [0.1, 0.2] vs [0.1, 0.2])
                        }
                    }
                }
                return false; // No intersection found
            };
        
            // If all checks pass, clear the error
            setError('');
        
            // Check if the range intersects with the override_ranges considering authoring and traffic partners
            const inputRange = [start, end];
            if (rangesIntersect(inputRange, override_ranges)) {
                setError('The entered range intersects with an existing override range for the selected partners.');
                return false;
            }
            
            return true;
        };

        const handleInputChange = (e) => {
            const value = e.target.value;
            setInputValue(value);
            validateInput(value);
        };

        const handleSubmit = async () => {
            if (!validateInput(inputValue)) {
              return;
            }
          
            try {
              const response = await fetch('/api/add_override_rule', {
                method: 'POST',
                headers: {
                  Authorization: `Bearer ${localStorage.getItem("token")}`,
                  "Content-Type": "application/json",
                },
                body: JSON.stringify({
                  AuthoringPartner: authoringPartner,
                  TrafficPartner: trafficPartner,
                  CPCMaxRange: maxCPC,
                  CPCMinRange: minCPC,
                  Margin: margin,
                }),
              });
          
              if (!response.ok) {
                const errorMsg = await response.text();
                console.error("Error response:", errorMsg); // Log the server's response for debugging
                alert("An error occurred while adding the override: " + errorMsg);
                throw new Error('Network response was not ok');
              }
          
              const result = await response.json();
              console.log('Success:', result);
              setOpen(false);
              window.location.reload(); // Refresh the page
            } catch (error) {
              console.error('Error:', error);
            }
        };


        const handleRowDeletion = async (row) => {
            try {
            const response = await fetch('/api/delete_override_rule', { // Adjust API route as needed
                method: 'POST',
                headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
                "Content-Type": "application/json",
                },
                body: JSON.stringify({  AuthoringPartner : row["AuthoringPartner"],
                                        TrafficPartner : row["TrafficPartner"],
                                        CPCMaxRange : row["CPCMaxRange"],
                                        CPCMinRange : row["CPCMinRange"],
                                        Margin : row["Margin"],
                                        Timestamp : row["Timestamp"]
                })
            });
        
            if (!response.ok) {
                alert("An error occurred while deleting the row...");
                throw new Error('Network response was not ok');
            }
        
            const result = await response.json();
            console.log('Success:', result);
            window.location.reload(); // Refresh the page after deletion
            alert("Row successfully deleted");
            } catch (error) {
            console.error('Error:', error);
            }
        };

        return (
            <React.Fragment>
            <Sheet
                className="OrderTableContainer"
                variant="outlined"
                sx={{
                display: { xs: "none", sm: "initial" },
                width: "100%",
                borderRadius: "sm",
                flexShrink: 1,
                overflow: "auto",
                minHeight: 0,
                }}
            >
            <Modal
                aria-labelledby="modal-title"
                aria-describedby="modal-desc"
                open={open}
                onClose={() => setOpen(false)}
                sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
            >
                <Sheet
                variant="outlined"
                sx={{
                    maxWidth: 1000,
                    borderRadius: "sm",
                    p: 3,
                    boxShadow: "sm",
                }}
                >
                <ModalClose
                    variant="outlined"
                    sx={{
                    top: "calc(-1/4 * var(--IconButton-size))",
                    right: "calc(-1/4 * var(--IconButton-size))",
                    boxShadow: "0 2px 12px 0 rgba(0 0 0 / 0.2)",
                    borderRadius: "50%",
                    bgcolor: "background.surface",
                    }}
                />
                <Typography
                    component="h1"
                    id="modal-title"
                    level="title-lg"
                    textColor="inherit"
                    fontWeight="lg"
                    mb={3}
                >
                    Add New Override Rule:
                </Typography>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            <Typography component="h2" level="body-lg">
                New % Margin on Traffic Source:
            </Typography>
            {/* Dropdown for Authoring Partner */}
            <label htmlFor="authoring-partner">Select Authoring Partner:</label>
        <select
            id="authoring-partner"
            value={authoringPartner}
            onChange={handleAuthoringPartnerChange}
            style={{ height: '40px', fontSize: '16px' }}
        >
            <option value="" disabled>Select Authoring Partner</option>
            {all_partners?.map((partner, index) => (
            <option key={index} value={partner}>
                {partner}
            </option>
            ))}
        </select>
        {/* Dropdown for Traffic Partner */}
        <label htmlFor="traffic-partner">Select Traffic Partner:</label>
        <select
            id="traffic-partner"
            value={trafficPartner}
            onChange={handleTrafficPartnerChange}
            style={{ height: '40px', fontSize: '16px' }}
        >
            <option value="" disabled>Select Traffic Partner</option>
            {all_partners?.map((partner, index) => (
            <option key={index} value={partner}>
                {partner}
            </option>
            ))}
        </select>

            {/* Input field for margin (whole number) */}
            <label htmlFor="override-margin">Margin (%):</label>
            <Input
                placeholder="Enter margin (whole number %)"
                value={margin}
                onChange={handleMarginChange}
                error={!validateMargin(margin)}
                aria-describedby="margin-error"
            />
            {!validateMargin(margin) && (
                <Typography id="margin-error" color="danger">
                Please enter a valid whole number for the margin.
                </Typography>
            )}

            {/* Input for minCPC and maxCPC range */}
            <label htmlFor="cpc-range">CPC Range:</label>

            <Input
                placeholder="Type a comma-separated minCPC, maxCPC (decimals)."
                value={inputValue}
                onChange={handleInputChange}
                error={!!error}
                aria-describedby="error-text"
            />
            {error && (
                <Typography id="error-text" color="danger">
                {error}
                </Typography>
            )}

            {/* Confirm and Cancel buttons */}
            <Button onClick={handleSubmit} variant="solid" disabled={!!error || !authoringPartner || !trafficPartner || !validateMargin(margin)}>
                Confirm
            </Button>
            <Button onClick={handleCancel} variant="solid" color="danger">
                Cancel
            </Button>
            </Box>
                </Sheet>
            </Modal>
                <Button
                    onClick={(e) => {
                        e.preventDefault();
                        // setModalWithData(row);
                        setOpen(true);
                    }}
                    sx={{ m: 2 }}
                >
                Add New Override
                </Button>
                <Table
                aria-labelledby="tableTitle"
                stickyHeader
                hoverRow
                sx={{
                    "--TableCell-headBackground":
                    "var(--joy-palette-background-level1)",
                    "--Table-headerUnderlineThickness": "1px",
                    "--TableRow-hoverBackground":
                    "var(--joy-palette-background-level1)",
                    "--TableCell-paddingY": "4px",
                    "--TableCell-paddingX": "8px",
                }}
                >
                <thead>
                    <tr>
                    <th style={{ padding: "12px 6px", textAlign: "center" }}>
                        Authoring Partner
                    </th>
                    <th style={{ padding: "12px 6px", textAlign: "center" }}>
                        Traffic Partner
                    </th>
                    <th style={{ padding: "12px 6px", textAlign: "center" }}>
                        CPC Min Range
                    </th>              
                    <th style={{ padding: "12px 6px", textAlign: "center" }}>
                        CPC Max Range
                    </th>
                    <th style={{ padding: "12px 6px", textAlign: "center" }}>
                        Margin
                    </th>
                    <th style={{ padding: "12px 6px", textAlign: "center" }}>
                        Creation Timestamp
                    </th>
                    <th style={{ padding: "12px 6px", textAlign: "center" }}>
                        Options
                    </th>
                    </tr>
                </thead>
                <tbody>
                    {overrides?.map((row, index) => (
                    <tr key={`${row.feedId}-${index}`}>
                        <td style={{ textAlign: "center" }}>{row["AuthoringPartner"]}</td>
                        <td style={{ textAlign: "center" }}>{row["TrafficPartner"]}</td>
                        <td style={{ textAlign: "center" }}>{row["CPCMinRange"]}</td>
                        <td style={{ textAlign: "center" }}>{row["CPCMaxRange"]}</td>
                        <td style={{ textAlign: "center" }}>{row["Margin"] * 100}%</td>
                        <td style={{ textAlign: "center" }}>{row["Timestamp"]}</td>
                        <td style={{ textAlign: "center" }}>
                            <Button color="danger" onClick={() => handleRowDeletion(row)}>Delete</Button>
                        </td>
                    </tr>
                    ))}
                </tbody>
                </Table>
            </Sheet>
            </React.Fragment>
        );
        }
