import React from "react";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  linearGradient,
  Defs,
  stop
} from "recharts";
import { useState, useEffect } from "react";

const sample_data = [
  { name: "03-01", "Orders Placed": 20},
  { name: "03-02", "Orders Placed": 24,},
  { name: "03-03", "Orders Placed": 26 },
  { name: "03-04", "Orders Placed": 30},
  { name: "03-05", "Orders Placed": 31},
  { name: "03-06", "Orders Placed": 31},
  { name: "03-07", "Orders Placed": 31},
  { name: "03-08", "Orders Placed": 18},
  { name: "03-09", "Orders Placed": 23},
  { name: "03-10", "Orders Placed": 22},
  { name: "03-11", "Orders Placed": 21},
  { name: "03-12", "Orders Placed": 25},
  { name: "03-13", "Orders Placed": 26},
  { name: "03-14", "Orders Placed": 26},
  { name: "03-15", "Orders Placed": 34},
  { name: "03-16", "Orders Placed": 18},
  { name: "03-17", "Orders Placed": 23},
  { name: "03-18", "Orders Placed": 26},
  { name: "03-19", "Orders Placed": 27},
  { name: "03-20", "Orders Placed": 24}
];

export default function ClicksChart({reviewStats}) {
  return (
    <ResponsiveContainer width="100%" height={400}>
      <AreaChart
        data={reviewStats || sample_data}  // Fallback to sample data
        margin={{
          top: 10,
          right: 30,
          left: 60,
          bottom: 5
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis
          label={{
            value: "USD ($)",
            angle: -90,
            position: "insideLeft",
            dy: -15,
            dx: -45
          }}
        />
        <Tooltip />
        <defs>
          <linearGradient id="colorValue" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor="#4682B4" stopOpacity={0.9} />
            <stop offset="95%" stopColor="#82ca9d" stopOpacity={0.08} />
            <stop offset="95%" stopColor="#ffc658" stopOpacity={0.08} />
          </linearGradient>
        </defs>
        <Area
          type="monotone"
          dataKey="All Earned" // change keys 
          stroke="#82ca9d"
          fillOpacity={.5}
          fill="#82ca9d"
        />
          <Area
          type="monotone"
          dataKey="Talent.com Earned" // change keys 
          stroke="#82ca9d"
          fillOpacity={.6}
          fill="#82ca9d"
        />
        <Area
          type="monotone"
          dataKey="jobg8 Earned" // change keys 
          stroke="#82ca9d"
          fillOpacity={.6}
          fill="#82ca9d"
        />
        <Area
          type="monotone"
          dataKey="jobrapido Earned" // change keys 
          stroke="#82ca9d"
          fillOpacity={.6}
          fill="#82ca9d"
        />
        <Area
          type="monotone"
          dataKey="jobget Earned" // change keys 
          stroke="#82ca9d"
          fillOpacity={.6}
          fill="#82ca9d"
        />
        <Area
          type="monotone"
          dataKey="jooble Earned" // change keys 
          stroke="#82ca9d"
          fillOpacity={.6}
          fill="#82ca9d"
        />
        <Area
          type="monotone"
          dataKey="jobvertise Earned" // change keys 
          stroke="#82ca9d"
          fillOpacity={.6}
          fill="#82ca9d"
        />

       {/* DC143C */}
       <Area
          type="monotone"
          dataKey="All Spent" // change keys 
          stroke="#DC143C"
          fillOpacity={.6}
          fill="#DC143C"
        />
       <Area
          type="monotone"
          dataKey="talent.com Spent" // change keys 
          stroke="#DC143C"
          fillOpacity={.6}
          fill="#DC143C"
        />
       <Area
          type="monotone"
          dataKey="jobg8 Spent" // change keys 
          stroke="#DC143C"
          fillOpacity={.6}
          fill="#DC143C"
        />
       <Area
          type="monotone"
          dataKey="jobrapido Spent" // change keys 
          stroke="#DC143C"
          fillOpacity={.6}
          fill="#DC143C"
        />
       <Area
          type="monotone"
          dataKey="jobget Spent" // change keys 
          stroke="#DC143C"
          fillOpacity={.6}
          fill="#DC143C"
        />
       <Area
          type="monotone"
          dataKey="jooble Spent" // change keys 
          stroke="#DC143C"
          fillOpacity={.6}
          fill="#DC143C"
        />
        <Area
          type="monotone"
          dataKey="jobvertise Spent" // change keys 
          stroke="#DC143C"
          fillOpacity={.6}
          fill="#DC143C"
        />
      </AreaChart>
    </ResponsiveContainer>
  );
}
