import { useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';

const ProtectedLayout = () => {
  const navigate = useNavigate();
  const isAuthenticated = !!localStorage.getItem('token');

  useEffect(() => {
    if (!isAuthenticated) {
      alert("Please login to use the CareerSphere.");
      navigate('/');
    }
  }, [isAuthenticated, navigate]);

  return <Outlet />;
};

export default ProtectedLayout;

