import * as React from "react";
import { CssVarsProvider, useColorScheme } from "@mui/joy/styles";

import Box from "@mui/joy/Box";
import CssBaseline from "@mui/joy/CssBaseline";
import IconButton from "@mui/joy/IconButton";

// Icons import
import DarkModeRoundedIcon from "@mui/icons-material/DarkModeRounded";
import LightModeRoundedIcon from "@mui/icons-material/LightModeRounded";
import AspectRatio from "@mui/joy/AspectRatio";

import Sidebar from "../../components/admin/Sidebar";
import Header from "../../components/Header";

import ClicksChart from "../../components/ClicksChart";

import { Typography } from "@mui/joy";
import framesxTheme from "../theme";
// import SampleSkeletonTable from "../components/SampleSkeletonTable";

// import AspectRatio from '@mui/joy/AspectRatio';
import Button from "@mui/joy/Button";
import Card from "@mui/joy/Card";
import CardContent from "@mui/joy/CardContent";
import CardActions from "@mui/joy/CardActions";
import FormControl from "@mui/joy/FormControl";
import FormLabel from "@mui/joy/FormLabel";
import Input from '@mui/joy/Input';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { NavLink } from 'react-router-dom';
import 'react-day-picker/dist/style.css';
import Table from "@mui/joy/Table";
import Sheet from "@mui/joy/Sheet";
// import Typography from '@mui/joy/Typography';
import Stack from "@mui/joy/Stack";
import { useState, useEffect } from "react";
// import DatePicker from "../components/DatePicker";
import SampleSkeletonTable from "../../components/SampleSkeletonTable";
import DataTable from "../../components/DataTable";
import IndividualClicksTable from "../../components/admin/IndividualClicksTable";
import { useNavigate } from "react-router-dom";
import FailedClicksTable from "../../components/admin/FailedClicksTable"

// Page shows how much the user spent.



function ColorSchemeToggle() {
  const { mode, setMode } = useColorScheme();
  const [mounted, setMounted] = React.useState(false);

  React.useEffect(() => {
    setMounted(true);
  }, []);
  if (!mounted) {
    return null;
  }
  return (
    <IconButton
      id="toggle-mode"
      size="lg"
      variant="solid"
      color="neutral"
      onClick={() => {
        if (mode === "light") {
          setMode("dark");
        } else {
          setMode("light");
        }
      }}
      sx={{
        position: "fixed",
        zIndex: 999,
        top: "1rem",
        right: "1rem",
        borderRadius: "50%",
        boxShadow: "sm"
      }}
    >
      {mode === "light" ? <DarkModeRoundedIcon /> : <LightModeRoundedIcon />}
    </IconButton>
  );
}




export default function TeamExample() {
  const [byMonthClickAuthored, setByMonthClickAuthored] = useState([]);
  const [byDayClickAuthored, setByDayClickAuthored] = useState([]);
  const [failedClickLog, setFailedClickLog] = useState([]);

  const navigate = useNavigate();
  const get_click_data = "/api/get_clickip_metadata";
  const get_failed_click_log = "/api/get_failed_clicks";
  const get_revenue_charts_data = "/api/get_revenue_charts_data";
  
  const [loadedTable, setLoadedTable] = useState(false);
  const [loadedTable2, setLoadedTable2] = useState(false);
  const [loadedTable3, setLoadedTable3] = useState(false);

  const [data, setData] = useState([]);
  useEffect(() => {
    const fetchData = async () => {      
      try {
        const response = await fetch(get_click_data, {
          method: "POST",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Content-Type": "application/json",
          },
        });        
        if (!response.ok) {
          alert("Your Session expired, please login again!")
          navigate("/admin/login");
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        setLoadedTable(true)
        setData(data)
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []); // Added startDate and endDate as dependencies for useEffect
  
  useEffect(() => {
    const fetchData = async () => {      
      try {
        const response = await fetch(get_failed_click_log, {
          method: "POST",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Content-Type": "application/json",
          },
        });        
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        // console.log("failed failed data:?? ", data)
        setFailedClickLog(data.failed_clicks)
        console.log(" * * data.failed_click: ", data.failed_clicks)
        setLoadedTable2(true)
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []); // Added startDate and endDate as dependencies for useEffect

  useEffect(() => {
    const fetchData = async () => {      
      try {
        const response = await fetch(get_revenue_charts_data, {
          method: "POST",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Content-Type": "application/json",
          },
        });        
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        setLoadedTable3(true)

        // Maybe just do authored clicks for now
        const all_month_clicks = data["all"].by_month_click_authored // .by_day_click_authored
        const talent_month_auth_clicks = data["talent.com"].by_month_click_authored
        const jobg8_month_auth_clicks = data["jobg8"].by_month_click_authored
        const jobrapido_month_auth_clicks = data["jobrapido"].by_month_click_authored
        const jobget_month_auth_clicks = data["jobget"].by_month_click_authored
        const jooble_month_auth_clicks = data["jooble"].by_month_click_authored
        const jobvertise_month_auth_clicks = data["jobvertise"].by_month_click_authored

        const all_day_clicks = data["all"].by_day_click_authored // .by_day_click_authored
        const talent_day_auth_clicks = data["talent.com"].by_day_click_authored
        const jobg8_day_auth_clicks = data["jobg8"].by_day_click_authored
        const jobrapido_day_auth_clicks = data["jobrapido"].by_day_click_authored
        const jobget_day_auth_clicks = data["jobget"].by_day_click_authored
        const jooble_day_auth_clicks = data["jooble"].by_day_click_authored
        const jobvertise_day_auth_clicks = data["jobvertise"].by_day_click_authored

        const dailyClickData = Object.entries(all_day_clicks).map(
          ([key, value]) => ({
              name: key,
              "All Clicks" : all_day_clicks[key],
              "talent.com Clicks" : talent_day_auth_clicks[key],
              "jobg8 Clicks" : jobg8_day_auth_clicks[key],
              "jobrapido Clicks": jobrapido_day_auth_clicks[key],
              "jobget Clicks" : jobget_day_auth_clicks[key],
              "jooble Clicks" : jooble_day_auth_clicks[key], 
              "jobvertise Clicks": jobvertise_day_auth_clicks[key]
          })
        );
        const monthlyClickData = Object.entries(all_month_clicks).map(
          ([key, value]) => ({
              name: key,
              "All Clicks" : all_month_clicks[key],
              "talent.com Clicks" : talent_month_auth_clicks[key],
              "jobg8 Clicks" : jobg8_month_auth_clicks[key],
              "jobrapido Clicks": jobrapido_month_auth_clicks[key],
              "jobget Clicks" : jobget_month_auth_clicks[key],
              "jooble Clicks" : jooble_month_auth_clicks[key], 
              "jobvertise Clicks": jobvertise_month_auth_clicks[key]
          })
        );
        setByDayClickAuthored(dailyClickData)
        console.log("* * dailyClickData: ", dailyClickData)
        setByMonthClickAuthored(monthlyClickData)
        console.log(" * * monthlyClickData: ", monthlyClickData)
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []); // Added startDate and endDate as dependencies for useEffect


  return (
    <CssVarsProvider disableTransitionOnChange theme={framesxTheme}>
      <CssBaseline />
      <Box sx={{ display: "flex", minHeight: "50dvh" }}> {/* Main flex container */}
        <Sidebar /> {/* Sidebar stays directly within the main flex container */}
        <Box sx={{ flex: 1, display: "flex", flexDirection: "column" }}> {/* New Box for main content */}
          <Header />
          <ColorSchemeToggle />
          <Box sx={{ width: '100%', my: 2 }}>
            <Typography 
                    component="h1" 
                    variant="h1" 
                    gutterBottom 
                    sx={{ fontSize: '3rem', fontWeight: 'bold', textAlign: 'center' }}
                >
                    Clicks Overview
            </Typography>
          </Box>
          <Typography 
                        component="h3" 
                        variant="h3" 
                        gutterBottom 
                        sx={{ fontSize: '2rem', fontWeight: 'bold', textAlign: 'center' }}
                        >
                        Click Log
            </Typography>
          {loadedTable ? (
            <Box sx={{ display: 'flex', justifyContent: 'space-evenly', width: '100%' }}>
              <IndividualClicksTable clickEntries={data}/>
            </Box>
          ) : (
            <SampleSkeletonTable />
          )}
          <Typography 
                        component="h3" 
                        variant="h3" 
                        gutterBottom 
                        sx={{ fontSize: '2rem', fontWeight: 'bold', textAlign: 'center' }}
                        >
                        Failed Click Log
          </Typography>
          {loadedTable2 ? (
            <Box sx={{ display: 'flex', justifyContent: 'space-evenly', width: '100%' }}>
              <FailedClicksTable clickEntries={failedClickLog}/>
            </Box>
          ) : (
            <SampleSkeletonTable />
          )}

        </Box>
      </Box>
    </CssVarsProvider>
  );
  
}
