      import * as React from 'react';
      import AspectRatio from '@mui/joy/AspectRatio';
      import Card from '@mui/joy/Card';
      import CardContent from '@mui/joy/CardContent';
      import CardOverflow from '@mui/joy/CardOverflow';
      import Chip from '@mui/joy/Chip';
      import IconButton from '@mui/joy/IconButton';
      import Link from '@mui/joy/Link';
      import Stack from '@mui/joy/Stack';
      import Typography from '@mui/joy/Typography';
      import WorkspacePremiumRoundedIcon from '@mui/icons-material/WorkspacePremiumRounded';
      import FavoriteRoundedIcon from '@mui/icons-material/FavoriteRounded';
      import FmdGoodRoundedIcon from '@mui/icons-material/FmdGoodRounded';
      import KingBedRoundedIcon from '@mui/icons-material/KingBedRounded';
      import WifiRoundedIcon from '@mui/icons-material/WifiRounded';
      import Star from '@mui/icons-material/Star';
      import BadgeIcon from '@mui/icons-material/Badge';
      import LanguageIcon from '@mui/icons-material/Language';
      import FactoryIcon from '@mui/icons-material/Factory';

      type RentalCardProps = {
        category: React.ReactNode;
        area: string;
        advertiserName : string;
        SalaryMaximum : string;
        SalaryMinimum : string;
        location: string;
        country: string;
        image: string;
        employment_type : string;
        liked?: boolean;
        rareFind?: boolean;
        title: React.ReactNode;
        applicationurl : string;
        onClick: React.MouseEventHandler<HTMLDivElement>;
      };

      export default function RentalCard(props: RentalCardProps) {
        // NOTE image is often null
        const { category, advertiserName, SalaryMinimum, SalaryMaximum, country, employment_type, area, title, location, rareFind = false, liked = false, image, applicationurl, onClick } = props;
        const [isLiked, setIsLiked] = React.useState(liked);
        return (
          <Card
            variant="outlined"
            orientation="horizontal"
            sx={{
              bgcolor: 'neutral.softBg',
              display: 'flex',
              flexDirection: { xs: 'column', sm: 'row' },
              '&:hover': {
                boxShadow: 'lg',
                borderColor: 'var(--joy-palette-neutral-outlinedDisabledBorder)',
              },
            }}
            onClick={onClick}
          >
            <CardOverflow
              sx={{
                mr: { xs: 'var(--CardOverflow-offset)', sm: 0 },
                mb: { xs: 0, sm: 'var(--CardOverflow-offset)' },
                '--AspectRatio-radius': {
                  xs: 'calc(var(--CardOverflow-radius) - var(--variant-borderWidth, 0px)) calc(var(--CardOverflow-radius) - var(--variant-borderWidth, 0px)) 0 0',
                  sm: 'calc(var(--CardOverflow-radius) - var(--variant-borderWidth, 0px)) 0 0 calc(var(--CardOverflow-radius) - var(--variant-borderWidth, 0px))',
                },
              }}
            >
              {/* {image ? (
              <AspectRatio
                ratio="1"
                flex
                sx={{
                  minWidth: { sm: 120, md: 160 },
                  '--AspectRatio-maxHeight': { xs: '160px', sm: '9999px' },
                }}
              >
                
                <img alt="" src={image} />
                
                <Stack
                  alignItems="center"
                  direction="row"
                  sx={{ position: 'absolute', top: 0, width: '100%', p: 1 }}
                >
                  {rareFind && (
                    <Chip
                      variant="soft"
                      color="success"
                      startDecorator={<WorkspacePremiumRoundedIcon />}
                      size="md"
                    >
                      Rare find
                    </Chip>
                  )}
                  <IconButton
                    variant="plain"
                    size="sm"
                    color={isLiked ? 'danger' : 'neutral'}
                    onClick={() => setIsLiked((prev) => !prev)}
                    sx={{
                      display: { xs: 'flex', sm: 'none' },
                      ml: 'auto',
                      borderRadius: '50%',
                      zIndex: '20',
                    }}
                  >
                    <FavoriteRoundedIcon />
                  </IconButton>
                </Stack>
              </AspectRatio>
              ) : null} */}
            </CardOverflow>
            <CardContent>
              <Stack
                spacing={1}
                direction="row"
                justifyContent="space-between"
                alignItems="flex-start"
              >
                <div>
                  <Typography level="body-sm">{advertiserName}</Typography>
                  <Typography level="title-md">
                    <Link
                      overlay
                      underline="none"
                      sx={{ color: 'text.primary' }}
                    >
                      {title}
                    </Link>
                  </Typography>
                </div>
                <IconButton
                  variant="plain"
                  size="sm"
                  color={isLiked ? 'danger' : 'neutral'}
                  onClick={() => setIsLiked((prev) => !prev)}
                  sx={{
                    display: { xs: 'none', sm: 'flex' },
                    borderRadius: '50%',
                  }}
                >
                  <FavoriteRoundedIcon />
                </IconButton>
              </Stack>
              <Stack
                spacing="0.25rem 1rem"
                direction="row"
                useFlexGap
                flexWrap="wrap"
                sx={{ my: 0.25 }}
              >
                <Typography level="body-xs" startDecorator={<FmdGoodRoundedIcon />}>
                  {area + ", " + location + " in " + country}
                </Typography>
                <Typography level="body-xs" startDecorator={<BadgeIcon />}>
                  { employment_type }
                </Typography>
          <Typography level="body-xs" startDecorator={<FactoryIcon />}>
            {category}
          </Typography>

              </Stack>
              <Stack direction="row" sx={{ mt: 'auto', width: '100%' }}>
      <Link href={applicationurl} target="_blank" rel="noopener noreferrer" sx={{ ml: 'auto' }}>
        <Typography level="title-lg" sx={{ textAlign: 'right' }} startDecorator={<LanguageIcon />}>
          <strong> Apply Now </strong>
        </Typography>
      </Link>
    </Stack>
            </CardContent>
          </Card>
        );
      }
