import * as React from 'react';
import Box from '@mui/joy/Box';
import Button from '@mui/joy/Button';
import IconButton, { iconButtonClasses } from '@mui/joy/IconButton';
import Typography from '@mui/joy/Typography';
import ArrowBackIosRoundedIcon from '@mui/icons-material/ArrowBackIosRounded';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';

type PaginationProps = {
  value: number;  // Current page number
  onChange: (page: number) => void;  // Function to change the page
  total: number;  // Total number of pages
};

export default function Pagination({ value, onChange, total }: PaginationProps) {
  const handlePrevious = () => {
    onChange(Math.max(1, value - 1));
  };

  const handleNext = () => {
    onChange(Math.min(total, value + 1));
  };

  // Function to generate page numbers dynamically
  const generatePageNumbers = () => {
    const pages = [];
    const sidePages = 3; // Number of pages to show on each side of the current page

    // Always include the first page
    pages.push({ number: 1, isCurrent: value === 1 });

    // Add ellipsis if there's a gap between page 1 and the range around current page
    if (value > 1 + sidePages + 1) {
        pages.push({ number: '...', isCurrent: false });
    }

    // Current page and the pages around it
    // Start from either 2 or closer to the current page depending on the gap
    const startPage = Math.max(2, value - sidePages);
    for (let i = startPage; i <= Math.min(total - 1, value + sidePages); i++) {
        pages.push({ number: i, isCurrent: i === value });
    }

    // Always include the last page
    if (value < total - sidePages - 1) {
        pages.push({ number: '...', isCurrent: false });
    }
    if (total > 1) {  // Check if total is greater than 1 to avoid duplicate page 1
        pages.push({ number: total, isCurrent: value === total });
    }

    return pages;
};


  return (
    <div>
      <Box
        className="Pagination-mobile"
        sx={{
          display: { xs: 'flex', md: 'none' },
          alignItems: 'center',
          mx: 2,
          my: 1,
        }}
      >
        <IconButton
          aria-label="previous page"
          variant="plain"
          color="neutral"
          size="sm"
          onClick={handlePrevious}
          disabled={value === 1}
        >
          <ArrowBackIosRoundedIcon />
        </IconButton>
        <Typography level="body-sm" mx="auto">
          Page {value} of {total}
        </Typography>
        <IconButton
          aria-label="next page"
          variant="plain"
          color="neutral"
          size="sm"
          onClick={handleNext}
          disabled={value === total}
        >
          <ArrowForwardIosRoundedIcon />
        </IconButton>
      </Box>
      <Box
        className="Pagination-laptopUp"
        sx={{
          gap: 1,
          [`& .${iconButtonClasses.root}`]: { borderRadius: '50%' },
          display: {
            xs: 'none',
            md: 'flex',
          },
          mx: 4,
          my: 2,
        }}
      >
        <Button
          size="sm"
          variant="plain"
          color="neutral"
          onClick={handlePrevious}
          disabled={value === 1}
          startDecorator={<ArrowBackIosRoundedIcon />}
        >
          Previous
        </Button>

        <Box sx={{ flex: 1 }} />
        {generatePageNumbers().map(({ number, isCurrent }) => (
          <IconButton
            key={number}
            size="sm"
            variant={isCurrent ? 'soft' : 'plain'}
            color={isCurrent ? 'primary' : 'neutral'}
            onClick={() => typeof number === 'number' && onChange(number)}
          >
            {number}
          </IconButton>
        ))}
        <Box sx={{ flex: 1 }} />

        <Button
          size="sm"
          variant="plain"
          color="neutral"
          onClick={handleNext}
          disabled={value === total}
          endDecorator={<ArrowForwardIosRoundedIcon />}
        >
          Next
        </Button>
      </Box>
    </div>
  );
}
