import * as React from "react";
import { useNavigate } from "react-router-dom";
import Box from "@mui/joy/Box";
import Sheet from "@mui/joy/Sheet";
import List from "@mui/joy/List";
import ListSubheader from "@mui/joy/ListSubheader";
import ListItem from "@mui/joy/ListItem";
import ListItemContent from "@mui/joy/ListItemContent";
import ListItemDecorator from "@mui/joy/ListItemDecorator";
import ListItemButton from "@mui/joy/ListItemButton";
import TroubleshootIcon from "@mui/icons-material/Troubleshoot";
import DynamicFeedIcon from '@mui/icons-material/DynamicFeed';
import HomeIcon from '@mui/icons-material/Home';
import MouseIcon from '@mui/icons-material/Mouse';
// icons
import {
  TrendingUp,
  CurrencyExchangeOutlined,
  ShoppingBagOutlined
} from "@mui/icons-material";
import {
  VpnKey,
  InventoryOutlined,
  SmartToyOutlined,
  BadgeOutlined,
  FeedOutlined
} from "@mui/icons-material";
import { HomeOutlined, ShoppingCartOutlined } from "@mui/icons-material";
import FeedIcon from "@mui/icons-material/Feed";
import StarsIcon from "@mui/icons-material/Stars";
import CreditCardRoundedIcon from "@mui/icons-material/CreditCardRounded";
import LogoutRoundedIcon from "@mui/icons-material/LogoutRounded";
import { RateReviewOutlined } from "@mui/icons-material";
import { closeSidebar } from "../../utils";
import { InterestsOutlined } from "@mui/icons-material";
// import { VolunteerActivism } from "@mui/icons-material";
import { Loyalty } from "@mui/icons-material";
import { Button, Card, Stack, LinearProgress, Typography } from "@mui/joy";
import IconButton from "@mui/joy/IconButton";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { useState, useEffect } from "react";
import WorkIcon from '@mui/icons-material/Work';

export default function Sidebar() {
  const navigate = useNavigate();



  function logout() {
    // Remove token from storage (example using localStorage)
    localStorage.removeItem("token");
    // Redirect user to login page or show a logged-out state
    navigate("/admin/login");
  }

  return (
    <React.Fragment>
      <Box
        className="Sidebar-overlay"
        sx={{
          position: "fixed",
          zIndex: 9998,
          top: 0,
          left: 0,
          width: "100vw",
          height: "100vh",
          opacity: "var(--SideNavigation-slideIn)",
          backgroundColor: "var(--joy-palette-background-backdrop)",
          transition: "opacity 0.4s",
          transform: {
            xs:
              "translateX(calc(100% * (var(--SideNavigation-slideIn, 0) - 1) + var(--SideNavigation-slideIn, 0) * var(--FirstSidebar-width, 0px)))",
            lg: "translateX(-100%)"
          }
        }}
        onClick={() => closeSidebar()}
      />
      <Sheet
        className="Sidebar"
        color="neutral"
        sx={{
          position: {
            xs: "fixed",
            lg: "sticky"
          },
          transform: {
            xs:
              "translateX(calc(100% * (var(--SideNavigation-slideIn, 0) - 1) + var(--SideNavigation-slideIn, 0) * var(--FirstSidebar-width, 0px)))",
            lg: "none"
          },
          transition: "transform 0.4s",
          zIndex: 9999,
          height: "100dvh",
          top: 0,
          p: 2,
          flexShrink: 0,
          display: "flex",
          flexDirection: "column",
          gap: 2,
          borderRight: "1px solid",
          borderColor: "divider"
        }}
      >
        <List
          size="sm"
          sx={{
            "--ListItem-radius": "6px",
            "--List-gap": "6px"
          }}
        >
          <ListSubheader
            role="presentation"
            sx={{ fontSize: "l", fontWeight: "lg" }}
          >
            Admin CareerSphere
          </ListSubheader>

          {/* 
          
                      <Route element={<Home />} path="/admin/dash/home" />
            <Route element={<Clicks />} path="/admin/dash/clicks" />
            <Route element={<Feeds/>} path="/admin/dash/feeds" />
          */}
          <ListItem>
            <ListItemButton onClick={() => navigate("/admin/dash/home")}>
              <ListItemDecorator>
                <HomeIcon />
              </ListItemDecorator>
              <ListItemContent>Home</ListItemContent>
            </ListItemButton>
          </ListItem>

          <ListItem>
            <ListItemButton onClick={() => navigate("/admin/dash/clicks")}>
              <ListItemDecorator>
                <MouseIcon />
              </ListItemDecorator>
              <ListItemContent>Clicks</ListItemContent>
            </ListItemButton>
          </ListItem>
          <ListItem>
            <ListItemButton onClick={() => navigate("/admin/dash/feeds")}>
              <ListItemDecorator>
                <DynamicFeedIcon />
              </ListItemDecorator>
              <ListItemContent>Feeds</ListItemContent>
            </ListItemButton>
          </ListItem>
          <ListItem>
            <ListItemButton onClick={() => logout()}>
              <ListItemDecorator>
                <LogoutRoundedIcon />
              </ListItemDecorator>
              <ListItemContent>Logout</ListItemContent>
            </ListItemButton>
          </ListItem>

        </List>
      </Sheet>
    </React.Fragment>
  );
}
