import * as React from 'react';
import Box from '@mui/joy/Box';
import Button from '@mui/joy/Button';
import Drawer from '@mui/joy/Drawer';
import DialogTitle from '@mui/joy/DialogTitle';
import ModalClose from '@mui/joy/ModalClose';
import Stack from '@mui/joy/Stack';
import FilterAltOutlined from '@mui/icons-material/FilterAltOutlined';
import { FormControl } from '@mui/joy';
import Input from '@mui/joy/Input';
import FormLabel from '@mui/joy/FormLabel';
import CountrySelector from './CountrySelector.jsx';
import StateSelector from './StateSelector.jsx';
import Option from "@mui/joy/Option";
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import axios from "axios";
import { useForm } from "react-hook-form";
import { useNavigate, useLocation } from 'react-router-dom';
import { useEffect } from "react";


import {
  Select,
  styled,
} from "@mui/joy";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function valueText(value: number) {
  return `$${value.toLocaleString('en-US')}`;
}

type FiltersProps = {
  setData: (data: []) => void;  // Function to change the page
  setTotalListingsNum: (data: number) => void; 
}

export default function Filters({ setData, setTotalListingsNum }: FiltersProps) {
  const [open, setOpen] = React.useState(false);
  const navigate = useNavigate();
  const query = useQuery();
  const industry_options_endpoint = "/api/get_industries_dropdown"

  const [jobQ, setJobQ] = React.useState(query.get("jobQ") || "");
  const [companyQ, setCompanyQ] = React.useState(query.get("companyQ") || "");
  const [countryQ, setCountryQ] = React.useState({ code: 'US', label: 'United States', phone: '61' });
  const [stateQ, setStateQ] = React.useState(query.get("stateQ") || "");
  const [cityQ, setCityQ] = React.useState(query.get("cityQ") || "");
  const [employmentTypeQ, setEmploymentTypeQ] = React.useState(query.get("employmentTypeQ") || "");
  const [remoteQ, setRemoteQ] = React.useState(query.get("remoteQ") || "");
  const [classificationQ, setClassificationQ] = React.useState(query.get("classificationQ") || "");
  const [industriesOptions, setIndustriesOptions] = React.useState([])

  const { register, handleSubmit } = useForm();

  const query_jobs_endpoint = "/api/get_jobs";

  // Event Handlers
  const handleJobQChange = (event) => {
    setJobQ(event.target.value);
  };
  const handleCompanyQChange = (event) => {
    setCompanyQ(event.target.value);
  };
  const handleCountryChange = (event, newValue) => {
    setCountryQ(newValue);
    if (newValue?.label !== "United States") {
      setStateQ("")
    }
  };
  const handleStateQChange = (event, newValue) => {
    setStateQ(newValue);
  };
  const handleCityQChange = (event) => {
    setCityQ(event.target.value)
  }
  const handleEmploymentTypeQChange = (event, newValue) => {
    setEmploymentTypeQ(newValue);
  };
  const handleRemoteQChange = (event, newValue) => {
    setRemoteQ(newValue);
  };

  const handleClassificationQChange = (event, newValue) => {
    setClassificationQ(newValue);
  };

  // Function to build the query string based on parameters that have values
  const buildQueryParams = (params) => {
    const searchParams = new URLSearchParams();
    for (const key in params) {
      if (params[key]) {
        searchParams.append(key, params[key]);
      }
    }
    return searchParams.toString();
  };

  useEffect(() => {
    const fetchItems = () => {
      fetch(industry_options_endpoint, {
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          setIndustriesOptions(data.unique_classifications);
        })
        .catch((error) => console.error("Error fetching industries options:", error));
    };
    // Start fetching initially
    fetchItems();
  }, []);

  // Submission
  const onSubmitFiltersQuery = async () => {
    const params = {
      jobQ,
      companyQ,
      countryQ: countryQ?.label,
      stateQ,
      cityQ,
      employmentTypeQ,
      remoteQ, 
      classificationQ
    };

    // Build query string with only parameters that have values
    const queryString = buildQueryParams(params);

    // Update URL with query parameters
    navigate(`?${queryString}`);

    // Make the API request
    const response = await axios.post(query_jobs_endpoint, params, {
      headers: {
        "Content-Type": "application/json",
      }
    });

    setData(response.data.jobs);
    setTotalListingsNum(response.data.totalCount);
    setOpen(false);
  };

  return (
    <Stack
      useFlexGap
      direction="row"
      spacing={{ xs: 0, sm: 2 }}
      justifyContent={{ xs: 'space-between' }}
      flexWrap="wrap"
      sx={{ minWidth: 0 }}
    >
      <Button
        variant="outlined"
        color="neutral"
        startDecorator={<FilterAltOutlined />}
        onClick={() => setOpen(true)}
      >
        Filters
      </Button>
      <Drawer open={open} onClose={() => setOpen(false)}>
        <Stack useFlexGap spacing={2} sx={{ p: 2 }}>
          <DialogTitle>Filters</DialogTitle>
          <ModalClose />
          <form onSubmit={handleSubmit(onSubmitFiltersQuery)}>
            <FormControl sx={{ flex: 1 }}>
              <FormLabel htmlFor="filters-end-date">Job</FormLabel>
              <Input
                placeholder="Search for Job"
                value={jobQ}
                onChange={handleJobQChange}
                startDecorator={<SearchIcon />}
                aria-label="Search"
              />
            </FormControl>

            <FormControl sx={{ flex: 1 }}>
              <FormLabel htmlFor="filters-company">Company</FormLabel>
              <Input
                placeholder="Search for Company"
                value={companyQ}
                onChange={handleCompanyQChange}
                startDecorator={<SearchIcon />}
                aria-label="Search"
              />
            </FormControl>

            <CountrySelector countryQ={countryQ} handleCountryChange={handleCountryChange} />
            <StateSelector stateQ={stateQ} handleStateChange={handleStateQChange} is_disabled={countryQ?.label !== "United States"} />
            <FormControl sx={{ flex: 1 }}>
              <FormLabel htmlFor="filters-city">City</FormLabel>
              <Input
                placeholder="Search for City"
                value={cityQ}
                onChange={handleCityQChange}
                startDecorator={<SearchIcon />}
                aria-label="Search"
              />
            </FormControl>

            <FormControl>
              <FormLabel htmlFor="filters-end-date">Employment Type</FormLabel>
              <Select
                variant="outlined"
                placeholder="All"
                value={employmentTypeQ}
                onChange={handleEmploymentTypeQChange}
                slotProps={{
                  listbox: {
                    variant: "outlined"
                  }
                }}
                sx={{ mr: -1.5, "&:hover": { bgcolor: "transparent" } }}
              >
                <Option value="All">All</Option>
                <Option value="Contract">Contract</Option>
                <Option value="Permanent">Permanent</Option>
              </Select>
            </FormControl>

            <FormControl>
              <FormLabel htmlFor="filters-end-date">Remote?</FormLabel>
              <Select
                variant="outlined"
                placeholder="All"
                value={remoteQ}
                onChange={handleRemoteQChange}
                slotProps={{
                  listbox: {
                    variant: "outlined"
                  }
                }}
                sx={{ mr: -1.5, "&:hover": { bgcolor: "transparent" } }}
              >
                <Option value="All">All</Option>
                <Option value="Remote">Remote</Option>
              </Select>
            </FormControl>

            <FormControl>
      <FormLabel htmlFor="filters-industry">Industry</FormLabel>
      <Select
        variant="outlined"
        placeholder="Select Industry"
        value={classificationQ}
        onChange={handleClassificationQChange}
        slotProps={{
          listbox: {
            variant: "outlined"
          }
        }}
        sx={{ mr: -1.5, "&:hover": { bgcolor: "transparent" } }}
      > 
        <Option value="All">All</Option>
        {industriesOptions.map((industry, index) => (
          <Option key={index} value={industry}>
            {industry}
          </Option>
        ))}
      </Select>
    </FormControl>

            <br />
            <Stack direction="row" spacing={2} justifyContent="center">
              <Button
                variant="solid"
                color="danger"
                startDecorator={<CloseIcon />}
                onClick={() => setOpen(false)}
              >
                Close
              </Button>
              <Button
                type="submit"
                variant="solid"
                color="primary"
                startDecorator={<SearchIcon />}
              >
                Search
              </Button>
            </Stack>
          </form>
        </Stack>
      </Drawer>
    </Stack>
  );
}
