import * as React from "react";
import { CssVarsProvider, useColorScheme } from "@mui/joy/styles";

import Box from "@mui/joy/Box";
import CssBaseline from "@mui/joy/CssBaseline";
import IconButton from "@mui/joy/IconButton";

// Icons import
import DarkModeRoundedIcon from "@mui/icons-material/DarkModeRounded";
import LightModeRoundedIcon from "@mui/icons-material/LightModeRounded";
import AspectRatio from "@mui/joy/AspectRatio";

import Sidebar from "../../components/admin/Sidebar";
import Header from "../../components/Header";
import { useNavigate } from "react-router-dom";
import FeedMarginTable from "../../components/admin/FeedMarginTable";

import ClicksChart from "../../components/ClicksChart";

import { Typography } from "@mui/joy";
import framesxTheme from "../theme";
// import SampleSkeletonTable from "../components/SampleSkeletonTable";

// import AspectRatio from '@mui/joy/AspectRatio';
import Button from "@mui/joy/Button";
import Card from "@mui/joy/Card";
import CardContent from "@mui/joy/CardContent";
import CardActions from "@mui/joy/CardActions";
import FormControl from "@mui/joy/FormControl";
import FormLabel from "@mui/joy/FormLabel";
import Input from '@mui/joy/Input';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { NavLink } from 'react-router-dom';
import 'react-day-picker/dist/style.css';
import Table from "@mui/joy/Table";
import Sheet from "@mui/joy/Sheet";
// import Typography from '@mui/joy/Typography';
import Stack from "@mui/joy/Stack";
import { useState, useEffect } from "react";
// import DatePicker from "../components/DatePicker";
import SampleSkeletonTable from "../../components/SampleSkeletonTable";
import DataTable from "../../components/DataTable";
import FeedCompChart from "../../components/admin/FeedCompChart";
import FeedActivityTable from "../../components/admin/FeedActivityTable";
import JobPartnerUpdateTable from "../../components/admin/JobPartnerUpdateTable";
import OverridesTable from "../../components/admin/OverridesTable";
// Page shows how much the user spent.



function ColorSchemeToggle() {
  const { mode, setMode } = useColorScheme();
  const [mounted, setMounted] = React.useState(false);
  React.useEffect(() => {
    setMounted(true);
  }, []);
  if (!mounted) {
    return null;
  }
  return (
    <IconButton
      id="toggle-mode"
      size="lg"
      variant="solid"
      color="neutral"
      onClick={() => {
        if (mode === "light") {
          setMode("dark");
        } else {
          setMode("light");
        }
      }}
      sx={{
        position: "fixed",
        zIndex: 999,
        top: "1rem",
        right: "1rem",
        borderRadius: "50%",
        boxShadow: "sm"
      }}
    >
      {mode === "light" ? <DarkModeRoundedIcon /> : <LightModeRoundedIcon />}
    </IconButton>
  );
}




export default function TeamExample() {
  const [byDayRevAuthored, setByDayRevAuthored] = useState([]);
  const [byDayClickAuthored, setByDayClickAuthored] = useState([]);
  // const []
  // TODO get today's delivered
  const get_feed_composition= "/api/get_feed_composition";
  const get_feed_log_activity = "/api/get_feed_pull_log";
  const get_margin_info = "/api/get_margin_info";
  const get_feed_change_log = "/api/get_feed_change_log";
  const get_overrides = "/api/get_override_rules"
  
  const [loadedTable, setLoadedTable] = useState(false);
  const [feedLogActiviy, setFeedLogActivity] = useState([]);
  const [feedCompData, setFeedCompData] = useState([]);
  const [feedMarginData, setFeedMarginData] = useState([]);
  const [feedChangeLog, setFeedChangeLog] = useState([]);
  const [overrides, setOverrides] = useState([]);
  const [overrideRanges, setOverrideRanges] = useState([]);
  const [allPartners, setAllPartners] = useState([]);
  const navigate = useNavigate();


  useEffect(() => {
    const fetchData = async () => {      
      try {
        const response = await fetch(get_feed_composition, {
          method: "POST",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Content-Type": "application/json",
          },
        });        
        if (!response.ok) {
          alert("Your Session expired, please login again!")
          navigate("/admin/login");
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        const transformedData = [];
        Object.keys(data).forEach(client => {
          Object.keys(data[client]).forEach(range => {
              const existing = transformedData.find(item => item.range === range);
              if (existing) {
                  existing[client] = data[client][range];
              } else {
                  transformedData.push({ range, [client]: data[client][range] });
              } 
            });
        });
        setFeedCompData(transformedData)
        // console.log("transformedData: ", transformedData)
        setLoadedTable(true)
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []); // Added startDate and endDate as dependencies for useEffect


  useEffect(() => {
    const fetchData2 = async () => {      
      try {
        const response = await fetch(get_feed_log_activity, {
          method: "POST",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Content-Type": "application/json",
          },
        });        
        if (!response.ok) {          
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        console.log("orderedData!: ", data)
        setFeedLogActivity(data)
        setLoadedTable(true)
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData2();
  }, []); // Added startDate and endDate as dependencies for useEffect
  
  useEffect(() => {
    const fetchData2 = async () => {      
      try {
        const response = await fetch(get_margin_info, {
          method: "POST",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Content-Type": "application/json",
          },
        });        
        if (!response.ok) {          
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        setFeedMarginData(data)
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData2();
  }, []); // Added startDate and endDate as dependencies for useEffect
  

  useEffect(() => {
    const fetchData3 = async () => {      
      try {
        const response = await fetch(get_feed_change_log, {
          method: "POST",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Content-Type": "application/json",
          },
        });        
        if (!response.ok) {          
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        setFeedChangeLog(data.job_change_log)
        console.log("job_change_log: ", data.job_change_log)
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData3();
  }, []); // Added startDate and endDate as dependencies for useEffect


  useEffect(() => {
    const fetchData4 = async () => {      
      try {
        const response = await fetch(get_overrides, {
          method: "POST",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Content-Type": "application/json",
          },
        });        
        if (!response.ok) {          
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        console.log("override data: ", data)
        setOverrides(data.overrides)
        setOverrideRanges(data.cpc_ranges)
        setAllPartners(data.all_partners)
        console.log("overrides: ", data.overrides)
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData4();
  }, []); // Added startDate and endDate as dependencies for useEffect



  return (
    <CssVarsProvider disableTransitionOnChange theme={framesxTheme}>
      <CssBaseline />
      <Box sx={{ display: "flex", minHeight: "50dvh" }}> {/* Main flex container */}
        <Sidebar /> {/* Sidebar stays directly within the main flex container */}
        <Box sx={{ flex: 1, display: "flex", flexDirection: "column" }}> {/* New Box for main content */}
          <Header />
          <ColorSchemeToggle />
          <Box sx={{ width: '100%', my: 2 }}>
          <Typography 
                    component="h1" 
                    variant="h1" 
                    gutterBottom 
                    sx={{ fontSize: '3rem', fontWeight: 'bold', textAlign: 'center' }}
                >
                    Feeds Overview
            </Typography>
          </Box>
          <Typography 
                        component="h3" 
                        variant="h3" 
                        gutterBottom 
                        sx={{ fontSize: '2rem', fontWeight: 'bold', textAlign: 'center' }}
                        >
                        Feed CPC Distribution ($)
          </Typography>
          {(loadedTable && feedCompData.length > 0)  ? (
            <Box sx={{ display: 'flex', justifyContent: 'space-evenly', width: '100%' }}>
              <FeedCompChart FeedCompInfo={feedCompData} />
            </Box>
          ) : (
            <SampleSkeletonTable />
          )}

          {loadedTable ? (
            <>
            <Box sx={{ display: 'flex', justifyContent: 'space-evenly', width: '100%' }}>
                <Typography 
                  component="h3" 
                  variant="h3" 
                  gutterBottom 
                  sx={{ fontSize: '2rem', fontWeight: 'bold', textAlign: 'center', marginRight: '1rem' }}
                >
                  Traffic Partner Feed Pull Log:
                </Typography>   
                <Typography 
                  component="h3" 
                  variant="h3" 
                  gutterBottom 
                  sx={{ fontSize: '2rem', fontWeight: 'bold', textAlign: 'center', marginRight: '1rem' }}
                >
                  Jobs Partner Update Log:
                </Typography>                  
                </Box>   
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-evenly', width: '100%' }}>
                  <FeedActivityTable FeedLog={feedLogActiviy} />
                  <JobPartnerUpdateTable JobUpdateLog={feedChangeLog} />
                </Box>            
              </Box>
            </>
          ) : (
            <SampleSkeletonTable />
          )}
          <br/>
          <Typography 
            component="h3" 
            variant="h3" 
            gutterBottom 
            sx={{ fontSize: '2rem', fontWeight: 'bold', textAlign: 'center' }}
            >
            Change Traffic Source Margin
          </Typography>
          {loadedTable ? (
            <Box sx={{ display: 'flex', justifyContent: 'space-evenly', width: '100%' }}>
              <FeedMarginTable marginInfo={feedMarginData}/>
            </Box>
          ) : (
            <SampleSkeletonTable />
          )}
        <br/>
        <Typography
            component="h3" 
            variant="h3" 
            gutterBottom 
            sx={{ fontSize: '2rem', fontWeight: 'bold', textAlign: 'center' }}
            >
            Manage Margin Overrides
        </Typography>
        {loadedTable ? (
            <Box sx={{ display: 'flex', justifyContent: 'space-evenly', width: '100%' }}>
              <OverridesTable overrides={overrides} override_ranges={overrideRanges} all_partners={allPartners}/>
            </Box>
          ) : (
            <SampleSkeletonTable />
          )}


        </Box>
      </Box>
    </CssVarsProvider>
  );
  
}
