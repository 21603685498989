
import reportWebVitals from './reportWebVitals';
//import react into the bundle
import React from "react";
import { createRoot } from "react-dom/client";
//import your own components
import Layout from "./layout";
import ReactGA from 'react-ga'; // Import react-ga
// import 'react-datepicker/dist/react-datepicker.scss';
import 'react-datepicker/dist/react-datepicker.css';

// require("react-datepicker/dist/react-datepicker-cssmodules.css");

ReactGA.initialize('G-37R6L36HG0'); // Initialize Google Analytics

const root = createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Layout />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))    
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
